<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header>
            Submissions
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('submitOrderLine')" class="btn btn-yellow btn-raised" @click="openAddSubmission">Add submission</mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            v-if="isAllowedTo('getAllOrderSubmissions')"
        />

        <p class="permission-message" v-else> Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="addSubmissionDialogActive">
            <template #header>
                <h3 class="font-weight-normal">Submit submission</h3>
            </template>

            <div class="row">
                <div class="col-12">
                    <p>Please enter the details for the submission</p>
                    <pretty-select
                        placeholder="Supplier name"
                        label="supplierName"
                        :options="supplierList"
                        v-model="$v.addSubmissionParams.supplierId.$model"
                        :reduce="supplier => supplier.supplierId"
                        :filter-by="supplierFilter"
                    />
                </div>
                <div class="col-12 mb-2">
                    <h5 class="h5 font-weight-normal my-1 ml-2">Supplier Id: {{$v.addSubmissionParams.supplierId.$model}}</h5>
                </div>
                <div class="col-12" :class="{'form-invalid': $v.addSubmissionParams.orderId.$error}">
                    <label>Order Id</label>
                    <mercur-input v-model.trim="$v.addSubmissionParams.orderId.$model" :disabled="addSubmissionDialogLoading" required>
                        <template #note>
                            <span class="form-error" v-if="!$v.addSubmissionParams.orderId.required">Order id is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-12" :class="{'form-invalid': $v.addSubmissionParams.orderLineId.$error}">
                    <label>Order line Id</label>
                    <mercur-input v-model.trim="$v.addSubmissionParams.orderLineId.$model" :disabled="addSubmissionDialogLoading" required>
                        <template #note>
                            <span class="form-error" v-if="!$v.addSubmissionParams.orderLineId.required">Order line id is required</span>
                        </template>
                    </mercur-input>
                </div>
            </div>
            <mercur-progress-bar indeterminate v-if="addSubmissionDialogLoading" />
            <template #footer>
                <mercur-button class="btn btn-primary" @click="addSubmissionDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-primary" @click="submitAddSubmission" :disabled="addSubmissionDialogLoading || $v.addSubmissionParams.$invalid">Submit</mercur-button>
            </template>
        </mercur-dialog>

        <div>
            <mercur-dialog width="80%" :is-open.sync="detailDialog">
                <div>
                    <div class="dialog__scroller">
                        <pre>{{ draft }}</pre>
                    </div>
                </div>
            </mercur-dialog>
        </div>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import PrettySelect from '../../components/utils/PrettySelect'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'

export default {
    name: 'OrderSubmissionsLogsOverview',
    components: { PrettySelect, DataTable, GridHeader },
    mixins: [validationMixin],
    data () {
        return {
            addSubmissionParams: {
                orderId: null,
                orderLineId: null,
                supplierId: null,
            },
            addSubmissionDialogActive: false,
            addSubmissionDialogLoading: false,
            detailDialog: false,
            draft: {},
            options: {
                masterDetail: true,
                // specify params for default detail cell renderer
                detailCellRendererParams: (params) => {
                    const submissionId = params.data.submissionId
                    return {
                        detailGridOptions: {
                            rowModelType: 'serverSide',
                            columnDefs: [
                                {
                                    field: 'dateCreated',
                                    sortable: true,
                                },
                                {
                                    field: 'logMessage',
                                },
                                {
                                    field: 'logType',
                                },
                                {
                                    field: 'logData',
                                    valueGetter: ({ data }) => JSON.stringify(data),
                                },
                                {
                                    field: '',
                                    cellRendererFramework: 'ActionsCell',
                                    cellRendererParams: () => {
                                        return {
                                            actions: [
                                                {
                                                    text: 'View Log',
                                                    icon: 'fas fa-eye',
                                                    onClick: params => {
                                                        this.triggerDetailModal(params.data)
                                                    },
                                                },
                                            ],
                                        }
                                    },
                                    suppressMenu: true,
                                    filter: false,
                                    sortable: false,
                                },
                            ],
                            onFirstDataRendered (params) {
                                params.columnApi.autoSizeAllColumns()
                            },
                            onGridReady: (params) => {
                                params.api.setServerSideDatasource(new ApiServerSideDataSource({
                                    $api: this.$api,
                                    url: CONFIG.API.ROUTES.ORDER_SUBMISSION.LOGS.DETAIL.replace('{submissionId}', submissionId),
                                    getFilterModel: () => this.filterModel,
                                    responseHandler: (successCallback, { data }) => {
                                        if (data.items.length === 0) {
                                            params.api.showNoRowsOverlay()
                                        }
                                        successCallback(data.items, data.items[0].totalRows)
                                    },
                                }))

                                setTimeout(() => {
                                    params.api.sizeColumnsToFit()
                                }, 100)
                                window.addEventListener('resize', () => {
                                    params.api.sizeColumnsToFit()
                                })
                            },
                        },
                    }
                },
                columns: {
                    'Submission': {
                        field: 'submissionId',
                        cellRenderer: this.isAllowedTo('getOrderSubmissionLogBySubmissionId') && 'agGroupCellRenderer',
                    },
                    'Order Line': {
                        field: 'orderLineIds',
                    },
                    'Supplier': {
                        field: 'supplierId',
                        valueFormatter: ({ value }) => {
                            if (!this.allSuppliers) {
                                return value
                            }
                            if (this.allSuppliers[value]) {
                                return this.allSuppliers[value].supplierName
                            }
                        },
                    },
                    'Retries': {
                        field: 'retries',
                    },
                    'Transport': {
                        field: 'submissionData.transport.transportFormat',
                        valueFormatter: ({ data }) => {
                            return `${data.submissionData.transport.transportClass} (${data.submissionData.transport.transportFormat})`
                        },
                    },
                    'Status': {
                        field: 'submissionStatus',
                        sortable: true,
                        statusChip: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
                detailRowHeight: 400,
                animateRows: true,
            },
            url: CONFIG.API.ROUTES.ORDER_SUBMISSION.LOGS.OVERVIEW,
            allSuppliers: null,
        }
    },
    computed: {
        supplierList () {
            if (!this.allSuppliers) {
                return []
            }

            return Object.values(this.allSuppliers)
        },
    },
    validations: {
        addSubmissionParams: {
            supplierId: {
                required,
            },
            orderId: {
                required,
            },
            orderLineId: {
                required,
            },
        },
    },
    methods: {
        supplierFilter (option, label, search) {
            if (option.supplierId === search) {
                return true
            }
            return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
        },
        triggerDetailModal (data) {
            this.draft = data
            this.detailDialog = true
        },
        openAddSubmission (params) {
            const submissionDetails = {
                orderId: null,
                orderLineId: null,
                supplierId: null,
            }

            if (params) {
                submissionDetails.orderLineId = params.orderLineId
                submissionDetails.supplierId = params.supplierId
                submissionDetails.orderId = params.orderId
            }

            this.addSubmissionParams = {
                ...this.addSubmissionParams,
                ...submissionDetails,
            }

            this.$v.$reset()
            this.addSubmissionDialogActive = true
        },
        submitAddSubmission () {
            if (!this.isAllowedTo('submitOrderLine')) {
                return
            }
            this.addSubmissionDialogLoading = true
            const url = CONFIG.API.ROUTES.ORDER_SUBMISSION.SUBMISSION.ADD
            const jobName = {
                url,
                params: this.addSubmissionParams,
            }
            this.addJob(jobName)
            this.$api.post(url, this.addSubmissionParams).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Submission scheduled for submitting.`,
                })
                this.addSubmissionDialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Submission scheduling failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.addSubmissionDialogLoading = true
                this.finishJob(jobName)
            })
        },
    },
    created () {
        if (!this.isAllowedTo('countOrdersBySupplierAndStatus') || !this.isAllowedTo('getSuppliersByParentId')) {
            return
        }
        const url = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(url)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            const supplierMap = data.data.items.reduce((acc, cur) => {
                acc[cur.supplierId] = cur
                return acc
            }, {})

            this.$set(this, 'allSuppliers', supplierMap)
            setTimeout(() => {
                this.$refs.grid.gridApi.redrawRows()
            }, 1)
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
